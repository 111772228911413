<!-- eslint-disable no-tabs -->
<template>
  <div class="booking-form-wrap">
    <div class="booking-form-inner primary-bg">
    <h3>{{ 'checkAvailability' | translate }}</h3>
    <p>{{ 'checkAvailabilityForThisTour' | translate }}</p>
    <div class="booking-form">
        <p>
					<input type="text"
							v-model="name" name="name"
							:placeholder="translate('yourName')" v-validate="'required'">
					<span
						v-show="errors.has('name')"
						class="invalid-feedback">
						{{ translate('requiredValid') }}
					</span>
        </p>
        <p>
					<input type="email" name="email"
							v-model="email" :placeholder="translate('yourEmail')" v-validate="'required|email'">
					<span v-show="errors.has('email')"
						class="invalid-feedback">
						{{ translate('emailValid') }}</span>
        </p>
        <p>
            <input type="text" name="phone"
                v-model="phone" :placeholder="translate('yourPhone')">
        </p>
				<p>
					<date-picker
							class="input-date-picker"
							v-model="checkInDate"
							format="DD-MM-YYYY"
							type="date"
							:placeholder="`${translate('startDate')} (DD-MM-YYYY)`"
					></date-picker>
				</p>
        <p>
            <input type="text" name="personNo"
                v-model="personNo" :placeholder="translate('personNo')">
        </p>
        <p>
            <button type="submit" @click="checkAvailability"
            class="outline-btn outline-btn-white">{{ 'checkAvailability' | translate }}</button>
        </p>
    </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import emailjs from 'emailjs-com';

export default {
  name: 'TourCheckAvailability',

  components: { DatePicker },

  data() {
    return {
      name: '',
      email: '',
      phone: '',
      personNo: 0,
      checkInDate: null,
    };
  },

  computed: {
    currentTourId() {
      return this.$store.getters.currentTourId;
    },
    tour() {
      return this.$store.getters.flatTours[this.currentTourId] || {};
    },
  },

  methods: {
    checkAvailability() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const day = this.checkInDate.getDate();
          const month = this.checkInDate.getMonth() + 1;
          const year = this.checkInDate.getFullYear();

          const templateParams = {
            from_name: '',
            form_emailphone: `${this.email}`,
            form_tour: this.selectedTour,
            form_date: `${day}/${month}/${year}`,
            form_personNo: this.personNo,
          };

          console.log('templateParams', templateParams);

          emailjs.send('service_7hn4vhc', 'template_4ebz93v', templateParams, 'b9Xjw4ic8AED2mAB_')
            .then(() => {
              this.$toast.success(this.translate('tourAvailabilityQuestioMessage'));
              // Reset form fields
              this.name = '';
              this.email = '';
              this.phone = '';
              this.personNo = 0;
              this.checkInDate = null;
            }, () => {
              this.$toast.error(this.translate('tourAvailabilityQuestioErrorMessage'));
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.invalid-feedback {
  display: block;
  color: red;
  font-size: 14px;
}
.mx-datepicker {
  display: initial;
}
</style>
